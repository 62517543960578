<template>
  <div class="ytm-navbar-2">
    <div class="ytm-logo">
      <svg viewBox="0 0 89 68" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 100%; margin-top: 0.3rem; cursor: pointer" @click="$router.push('/')">
        <path d="M6.35838 47.7974C4.28307 47.7974 2.69348 47.2777 1.5896 46.2383C0.529869 45.1556 0 43.6182 0 41.6261V8.17128C0 6.17915 0.529869 4.6634 1.5896 3.62402C2.69348 2.54134 4.28307 2 6.35838 2H28.8114C30.401 2 31.5932 2.38977 32.388 3.1693C33.1828 3.94883 33.5802 5.07481 33.5802 6.54726C33.5802 8.06301 33.1828 9.23231 32.388 10.0551C31.5932 10.8347 30.401 11.2244 28.8114 11.2244H11.657V19.9292H27.3542C28.8997 19.9292 30.0698 20.319 30.8646 21.0985C31.7036 21.878 32.123 23.0257 32.123 24.5414C32.123 26.0572 31.7036 27.2048 30.8646 27.9843C30.0698 28.7639 28.8997 29.1536 27.3542 29.1536H11.657V38.5729H28.8114C30.401 38.5729 31.5932 38.9844 32.388 39.8072C33.1828 40.5867 33.5802 41.7127 33.5802 43.1852C33.5802 44.7009 33.1828 45.8486 32.388 46.6281C31.5932 47.4076 30.401 47.7974 28.8114 47.7974H6.35838Z" fill="black"/>
        <path d="M6.96973 6.45264H83.0003" stroke="black" stroke-width="11.6355" stroke-linecap="round"/>
        <path d="M6.96973 24.2627H83.0003" stroke="black" stroke-width="11.6355" stroke-linecap="round"/>
        <path d="M6.96973 42.7087H83.0003" stroke="black" stroke-width="11.6355" stroke-linecap="round"/>
        <path d="M22.3376 65.6074C15.797 60.3848 17.4108 51.1449 20.004 46.5251H27.7833C22.96 51.5065 25.9103 60.3848 29.1448 63.7996C24.9378 63.3979 22.3376 60.3848 22.3376 60.3848C22.0266 60.5456 21.6318 63.5988 22.3376 65.6074Z" fill="black" stroke="black" stroke-width="1.5514"/>
      </svg>
    </div>
    <div style="flex-grow: 0; display: flex; align-content: space-between">
      <div class="ytm-navbar-component">
        <div style="display: table-cell; vertical-align: middle">
          <a href="/courses" style="display: flex; cursor: pointer" @click.prevent="$router.push('/courses')">
            <svg viewBox="0 0 29 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-navbar-icon">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M6.69238 4C6.69238 1.79086 8.48328 0 10.6924 0H25.0001C27.2092 0 29.0001 1.79086 29.0001 4V25C29.0001 27.2091 27.2092 29 25.0001 29H24.5386V9.57692C24.5386 7.36778 22.7477 5.57692 20.5386 5.57692H6.69238V4ZM4 8C1.79089 8 0 9.79086 0 12V33C0 35.2091 1.79089 37 4 37H18C20.2091 37 22 35.2091 22 33V12C22 9.79086 20.2091 8 18 8H4Z" fill="black"/>
            </svg>
            <div v-if="!isMobile" style="display: table; height: 1.5rem; margin-left: 0.9rem">
              <p class="ytm-navbar-text" style="display: table-cell; vertical-align: middle">
                Мои курсы
              </p>
            </div>
          </a>
        </div>
      </div>
      <div class="ytm-navbar-component">
        <div style="display: table-cell; vertical-align: middle">
          <a href="/home" style="display: flex; cursor: pointer" @click.prevent="$router.push('/home')">
            <svg viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-navbar-icon">
              <path fill-rule="evenodd" clip-rule="evenodd" d="M2 0C0.895431 0 0 0.895431 0 2V14C0 15.1046 0.895431 16 2 16H14C15.1046 16 16 15.1046 16 14V2C16 0.895431 15.1046 0 14 0H2ZM2 20C0.895431 20 0 20.8954 0 22V34C0 35.1046 0.895431 36 2 36H14C15.1046 36 16 35.1046 16 34V22C16 20.8954 15.1046 20 14 20H2ZM20 2C20 0.895431 20.8954 0 22 0H34C35.1046 0 36 0.895431 36 2V14C36 15.1046 35.1046 16 34 16H22C20.8954 16 20 15.1046 20 14V2ZM20.4714 21.4182C19.3909 21.6472 18.7006 22.7089 18.9297 23.7894L21.4182 35.5286C21.6472 36.6091 22.7089 37.2994 23.7894 37.0703L35.5286 34.5818C36.6091 34.3528 37.2994 33.2911 37.0703 32.2106L34.5818 20.4714C34.3528 19.3909 33.2911 18.7006 32.2106 18.9297L20.4714 21.4182Z" fill="black"/>
            </svg>
            <div v-if="!isMobile" style="display: table; height: 1.5rem; margin-left: 0.9rem">
              <p class="ytm-navbar-text" style="display: table-cell; vertical-align: middle">
                Расписание
              </p>
            </div>
          </a>
        </div>
      </div>
      <div class="ytm-navbar-component">
        <div style="display: table-cell; vertical-align: middle">
          <svg viewBox="0 0 33 37" fill="none" xmlns="http://www.w3.org/2000/svg" class="ytm-navbar-icon">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M19.3145 3.72357C19.3581 3.51702 19.381 3.30286 19.381 3.08333C19.381 1.38046 18.0006 0 16.2977 0C14.5948 0 13.2144 1.38046 13.2144 3.08333C13.2144 3.29107 13.2349 3.49401 13.2741 3.69023C10.2678 4.12632 6.59021 6.08245 5.06561 11.6727C4.67048 13.1215 4.67473 15.2405 4.67897 17.3561C4.68505 20.386 4.69112 23.4089 3.52394 24.4464H28.8513C27.6841 23.4089 27.6902 20.386 27.6963 17.3561C27.7005 15.2405 27.7048 13.1215 27.3097 11.6727C25.8209 6.21399 22.2794 4.22039 19.3145 3.72357ZM20.7026 32.5952C20.7026 35.0279 18.7305 37 16.2979 37C13.8652 37 11.8931 35.0279 11.8931 32.5952C11.8931 31.9688 12.0239 31.3729 12.2596 30.8334H1.54166C0.690226 30.8334 0 30.1431 0 29.2917C0 28.4403 0.690228 27.75 1.54167 27.75H31.0536C31.905 27.75 32.5952 28.4403 32.5952 29.2917C32.5952 30.1431 31.905 30.8334 31.0536 30.8334H20.3361C20.5719 31.3729 20.7026 31.9688 20.7026 32.5952Z" fill="black"/>
          </svg>
        </div>
      </div>
      <div class="ytm-navbar-component" style="position: relative">
        <div style="display: table-cell; vertical-align: middle">
          <svg viewBox="0 0 36 37" fill="none" xmlns="http://www.w3.org/2000/svg" tabindex="0" class="ytm-navbar-icon"
               @focus="showDropdown = true" @blur="showDropdown = false"
          >
            <path fill-rule="evenodd" clip-rule="evenodd" d="M18 0C12.4772 0 8.00001 4.47715 8.00001 10V11C8.00001 16.5228 12.4772 21 18 21C23.5229 21 28 16.5228 28 11V10C28 4.47715 23.5229 0 18 0ZM5.9857 36.5H29.2429C31.5269 36.5 34.5333 36.3379 35.0786 34.1199C35.3305 33.0955 34.8268 31.848 32.7086 30.5C29.309 28.3367 25.8895 27.0289 23.7416 26.3924C22.7314 26.093 21.677 26 20.6235 26L14.5321 26C13.6512 26 12.7712 26.0631 11.9148 26.2696C9.5006 26.8518 5.51332 28.1868 2.20862 30.5C0.563981 31.6513 -0.00999421 32.7293 0.000140916 33.6576C0.0272593 36.1413 3.50178 36.5 5.9857 36.5Z" fill="black"/>
          </svg>
        </div>
        <div v-if="showDropdown" class="ytm-navbar-dropdown" tabindex="-1">
          <div style="display: flex; cursor: pointer; padding: 0 1rem; margin: 0.5rem 0" @mousedown="$router.push('/account')">
            <div style="flex-grow: 1; margin-right: 1rem; display: table">
              <p class="ytm-navbar-dropdown-text" style="display: table-cell; vertical-align: middle">
                Личный кабинет
              </p>
            </div>
            <div style="display: table">
              <div style="display: table-cell; vertical-align: middle">
                <svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 0.9rem">
                  <path d="M1 1L8 8L1 15" stroke="#00171F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
          <hr style="margin: 0">
          <!--          <div style="display: flex; cursor: pointer; padding: 0 1rem; margin: 0.5rem 0">-->
          <!--            <div style="flex-grow: 1; margin-right: 1rem; display: table">-->
          <!--              <p class="ytm-navbar-dropdown-text" style="display: table-cell; vertical-align: middle">-->
          <!--                Аккаунт-->
          <!--              </p>-->
          <!--            </div>-->
          <!--            <div style="display: table">-->
          <!--              <div style="display: table-cell; vertical-align: middle">-->
          <!--                <svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 0.9rem">-->
          <!--                  <path d="M1 1L8 8L1 15" stroke="#00171F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>-->
          <!--                </svg>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->
          <!--          <hr style="margin: 0">-->
          <div style="display: flex; cursor: pointer; padding: 0 1rem; margin: 0.5rem 0" @mousedown="$store.dispatch('auth/logout')">
            <div style="flex-grow: 1; margin-right: 1rem; display: table">
              <p class="ytm-navbar-dropdown-text" style="display: table-cell; vertical-align: middle">
                Выйти
              </p>
            </div>
            <div style="display: table">
              <div style="display: table-cell; vertical-align: middle">
                <svg viewBox="0 0 9 16" fill="none" xmlns="http://www.w3.org/2000/svg" style="height: 0.9rem">
                  <path d="M1 1L8 8L1 15" stroke="#00171F" stroke-width="1.75" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {inject} from "vue";

export default {
  name: 'Navbar',
  setup() {
    let screenWidth = inject('screen_width');
    return {screenWidth};
  },
  computed: {
    isMobile() {
      return this.screenWidth < 600;
    },
  },
  data() {
    return {
      showDropdown: false,
    };
  },
};
</script>

<style scoped>
.ytm-navbar-2 {
  position: relative;
  width: 100%;
  display: flex;
  padding-top: 0.8rem;
}

.ytm-logo {
  flex-grow: 1;
  margin-right: 2rem;
  height: 3.5rem;
  user-select: none;
}

.ytm-navbar-component {
  margin-left: 2.5rem;
  height: 100%;
  display: table;
}

.ytm-navbar-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 1rem;
  line-height: 116.6%;
  color: #000000;
  user-select: none;
  -webkit-user-select: none;
}

.ytm-navbar-icon {
  display: flex;
  height: 1.5rem;
  width: 1.5rem;
  cursor: pointer;
  outline-style: none;
}

@media only screen and (max-width: 700px) {
  .ytm-logo {
    margin-right: 1rem;
    height: 3rem;
  }

  .ytm-navbar-component {
    margin-left: 1.5rem;
  }

  .ytm-navbar-icon {
    height: 1.25rem;
    width: 1.25rem;
  }
}

.ytm-navbar-dropdown {
  z-index: 1000;
  position: absolute;
  top: calc(50% + 1.5rem);
  right: 0;
  padding: 0.5rem 0.5rem;
  border-radius: 1rem;
  background-color: #FFFFFF;
  box-shadow: 5px 5px 10px 2px rgba(0, 0, 0, 0.25);
}

.ytm-navbar-dropdown-text {
  font-family: 'Montserrat', sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 116.6%;
  white-space: nowrap;
  user-select: none;
  -webkit-user-select: none;
}
</style>